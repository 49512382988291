import axios from 'axios'
import Vue from 'vue'
// import qs from 'qs';

// const BASEURL =  process.env.NODE_ENV === 'production'?'https://www.520mg.com':'';
// const BASEURL = 'http://192.168.3.17:8666/' //李闯本地地址
// const BASEURL = 'http://192.168.3.40:8666/' //杨森本地地址
const BASEURL = 'http://sygp.shiyuntech.net' //生产地址
// const BASEURL = 'http://shiyun.dljianwei.com'  //测试地址
// const BASEURL = 'http://192.168.5.110:6333'  //测试地址
 
// const BASEURL = 'http://192.168.5.110:6333/'
// 设置基础URL  (动态的根据当前的环境不一样,设置不一样的baseurl)
// process.env webpack的全局变量环境 产品|开发环境判断 给不同BASEURL
// const BASEURL = 'http://192.168.5.80:8788' //李xz本地地址/
const request = axios.create({
  baseURL: BASEURL,
  timeout: 100000
})

Vue.prototype.$url = BASEURL

// 创建一个axios实例,设置实例的基础url和 超时时间
// requset.拦截器.请求.使用7410.0

request.interceptors.request.use(
  config => {
    config.headers.token = localStorage.getItem('token')
    return config
  },
  err => Promise.reject(err)
)
// use 两个回调函数 ，第一个回调函数代表正确 reslove，第个回调函数错误 reject
// Promise.reject  再一次向上一一级抛出一个reject错误你
// 发送请求前个请求头headers 添加 token 头信息
// (每当是axios发起请求时候,先执行use里面回调函数方法,)
// (比如每次发起ajax请求 添加token，添加用户名 到header头信息里面)

// requset.拦截器.响应.使用
request.interceptors.response.use(
  res => {
    // 写一些业务逻辑  关闭加载提示,301 404 500 等错误响应
    //  res.data.status ==1 ==2 ==3 响应的公共逻辑
    if (res.data.status.code == 6000) {
      localStorage.clear()
      // console.log(res.data.status.code);
      //window.location.href ='http://sygp.shiyuntech.net/html/';//正式
      window.location.href = '/login'  //本地
      // console.log(res.data.status.code);
    }
    return res
  },
  err => Promise.reject(err)
)
// 响应请求拦截

// 拦截器就是再请求前，响应前 做一些额外的公共的的事情  请求前添加loading显示 响应前 移除loading
// request是有方法 也是 对象 对象的属性是可以动态的添加（动态添加了一个方法叫postURL）
request.post = function (url, data, option = {}) {
  return new Promise(function (resolve, reject) {
    request({
      url: url,
      method: 'POST',
      data: data,
      // data:qs.stringify(data),
      ...option,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', ...option.headers }
    })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  })
}
// 添加一个 postURL方法,
// 最终返回的 当执行 postURL这个方法是最终返回的Promise对象的实例
// prosmie对象的实例 有两个结果 .then .catch（异步拿到-等待任意时间获取） 回调函数 成功
// .then 异步获取到 reslove 返回的数
// .catch 获取到 reject返回的 错误信息

export default request

<template>
    <div id="body">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ path: '/SchoolHome' }"
                >首页</el-breadcrumb-item
            > -->
            <el-breadcrumb-item :to="{ path: '/set_user' }"
                >设置</el-breadcrumb-item
            >
            <el-breadcrumb-item>绑定手机</el-breadcrumb-item>
        </el-breadcrumb>
        <br /><br />
        <el-form>
            <br />
            <el-form-item label="当前手机号">
                <el-input
                    v-model="totel"
                    placeholder="当前手机号"
                    number
                ></el-input>
            </el-form-item>
            <el-form-item label="新手机号">
                <el-input v-model="newtel" placeholder="新手机号"></el-input>
            </el-form-item>
            <el-button :disabled="isSend" @click="send_code()">{{
                send_code_text
            }}</el-button>
            <el-form-item label="验证码">
                <el-input
                    v-model="code"
                    placeholder="验证码"
                    show-password
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { sendSms, telChange } from "@/api/setUser.js";
export default {
    data() {
        return {
            tel: "",
            isSend: false,
            send_code_text: "发送验证码",
            code: "",
            totel: "",
            newtel: "",
        };
    },
    mounted() {
        this.set_tel();
    },
    methods: {
        // 手机号
        set_tel() {
            var tel = this.$route.params.tel;
            if (tel) {
                localStorage.setItem("set_tel", tel);
                this.tel = tel;
            } else {
                if (localStorage.getItem("set_tel")) {
                    this.tel = localStorage.getItem("set_tel");
                }
            }
        },
        // 发送验证码
        send_code() {
            // 新旧手机号不能相同
            if (this.totel == this.newtel) {
                this.$message.error("新手机号不能与当前手机号相同");
                return;
            }
            // 新手机号
            if (!this.newtel) {
                this.$message.error("新手机号不能为空");
                return;
            }
            var reg = /^\d{11}$/;
            if (!reg.test(this.newtel)) {
                this.$message.error("新手机号格式错误");
                return;
            }

            this.isSend = true;

            // 发送短信
            var data = {};
            data.phone = this.newtel;
            data.type = 3;
            this.sendSms(data);

        },
        // 发短信
        sendSms(data) {
            sendSms(data)
                .then((res) => {
                    const res_data =JSON.parse(res.data.data)
                    // 重新开始倒计时
                    if (res_data.status.code == 1) {
                      this.$message({
                        message: "发送成功",
                        type: "success",
                      });
                      // 60秒倒计时
                      var s = 60;
                      this.send_code_text = "已发送 " + s;
                      var _this = this;
                      var sendCode = setInterval(function () {
                          if (s < 1) {
                              window.clearInterval(sendCode);
                              _this.isSend = false;
                              _this.send_code_text = "发送验证码";
                          } else {
                              s = s - 1;
                              _this.send_code_text = "已发送 " + s;
                              console.log(_this.send_code_text);
                          }
                      }, 1000);
                      
                    } else {
                      this.isSend = false;
                      this.$message.error(res_data.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        // 提交修改
        telChange(data) {
            telChange(data)
                .then((res) => {
                    if (res.status.code == 1) {
                        // 发送成功
                        this.$message({
                            message: "修改成功",
                            type: "success",
                        });
                        this.$router.push({
                            name: "set_user",
                        });
                    } else {
                        this.$message.error(res.status.msg);
                    }
                })
                .catch((err) => {
                    console.log(res);
                });
        },
        submitForm(formName) {
            // 当前手机号
            this.totel = this.totel.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.totel) {
                this.$message.error("当前手机号不能为空");
                return;
            }
            // 新手机号
            this.newtel = this.newtel.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.newtel) {
                this.$message.error("新手机号不能为空");
                return;
            }
            // 新旧手机号不能相同
            if (this.totel == this.newtel) {
                this.$message.error("新手机号不能与当前手机号相同");
                return;
            }
            // 验证码
            this.code = this.code.replace(/(^\s*)|(\s*$)/g, "");
            if (!this.code) {
                this.$message.error("验证码不能为空");
                return;
            }
            // 提交修改
            var data = {};
            data.ytel = this.totel;
            data.tel = this.newtel;
            data.code = this.code;
            this.telChange(data);
        },
    },
};
</script>
<style lang="scss" scoped>
#body {
    margin: 30px;
    width: 600px;
}
.header {
    .info {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        margin-left: 50px;
    }
}
</style>

import request from '@/api/request.js'

// 学校 -> 设置 -> 获取信息
export function optionInfo(data) {
    return request.post('/school/optionInfo')
}
// 学校 -> 设置 -> 操作记录 -> 登录日志
export function schoolLogList(data) {
    return request.post('/school/schoolLogList', data)
}
// 学校 -> 设置 -> 操作记录 -> 班级日志
export function classLogList(data) {
    return request.post('/school/classLogList', data)
}
// 学校 -> 设置 -> 操作记录 -> 学生日志
export function stuLogList(data) {
    return request.post('/school/stuLogList', data)
}
// 学校 -> 设置 -> 操作记录 -> 视力日志
export function healthLogList(data) {
    return request.post('/school/healthLog', data)
}
// 学校 -> 设置 -> 账号密码 -> 发送短信
export function sendSms(data) {
    return request.post('/adm/sendSmsNew', data)
}
// 学校 -> 设置 -> 账号密码 -> 修改密码
export function passwdChange(data) {
    return request.post('/school/passwdChange', data)
}
// 学校 -> 设置 -> 账号密码 -> 绑定手机
export function telChange(data) {
    return request.post('/school/telChange', data)
}
// 学校 -> 设置 -> 教师 -> 解除关系
export function exitSchool(data) {
    return request.post('/teacher/exitSchool', data)
}
